import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { MuiFileInput } from "mui-file-input";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Canvas } from "./canvas";
import { resizeImage } from "../util/common";
import { Input } from "@mui/joy";

export type onUploadInputArgs = {
  description?: string | null
  image?: File | null
}

interface ImageUploadArgs {
  onInput?: (args: onUploadInputArgs) => void
}

export function ImageUpload(props: ImageUploadArgs) {
  const { onInput } = props;
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageDescription, setImageDescription] = useState<string | null>(null);
  const canvasContainerRef = useRef<HTMLDivElement | null>(null);
  const [canvasWidth, setCanvasWidth] = useState(0);

  // Update width state as canvas is rendered
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const width = canvasContainerRef.current?.offsetWidth;
    if (width && (width !== canvasWidth)) {
      setCanvasWidth(width);
    }
  });

  const canvasInstance = useMemo(() => (
    <Canvas imageFile={selectedImage} width={canvasWidth} />
  ), [canvasWidth, selectedImage]);

  const handleFileSelect = useCallback(async (file: File | null) => {
    if (!canvasWidth || !file) return;
    // Restrict file size to AI generator's max supported dimensions, maintaining original aspect ratio.
    const resizedSelectedFile = (await resizeImage({
      file,
      compressFormat: "jpeg",
      maxHeight: 4000, // TBD
      outputType: "file",
      quality: 70,
      maxWidth: 4000,
    })) as File;
    if (!resizedSelectedFile) throw new Error("Resize failed");
    setSelectedImage(resizedSelectedFile);

    if (onInput) {
      onInput({ description: imageDescription, image: resizedSelectedFile });
    }
  }, [canvasWidth, imageDescription, onInput]);

  const handleImageDescription = useCallback((event: any) => {
    event.preventDefault();
    const { value } = event.target;
    setImageDescription(value);
    if (onInput) {
      onInput({ description: imageDescription, image: selectedImage });
    }
  }, [onInput, imageDescription, selectedImage, setImageDescription]);

  return (
    <Sheet variant="outlined" style={containerStyle}>
      <Typography
        component="h2"
        id="close-modal-title"
        level="h4"
        textColor="primary.600"
        sx={{ fontWeight: "lg", m: 1 }}
      >
        Upload Image
      </Typography>
      <Input
        onChange={handleImageDescription}
        placeholder="Add a description ..."
        style={inputStyle}
        value={imageDescription ?? ""}
      />
      <MuiFileInput
        onChange={handleFileSelect}
        InputProps={{
          inputProps: { accept: "image/*" },
          startAdornment: <AttachFileIcon />,
        }}
        value={selectedImage}
        placeholder="Select an image ..."
      />
      <div ref={canvasContainerRef}>
        {canvasInstance}
      </div>
    </Sheet>
  );
}

const containerStyle = {
  borderRadius: "md",
  padding: 3,
};

const inputStyle = {
  marginBottom: "1rem",
};
