import {
  ApolloClient, DocumentNode, FetchPolicy, NormalizedCacheObject, gql, useMutation, useQuery,
} from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Image, ImageConnection, UploadImageUrlPayload, UploadUrlInput,
} from "../models/image";
import { apolloClient, getSubErrors } from "./apolloClient";

// Image fields used by AI generator
export const imageGenFragment = gql`
  fragment imageGenFragment on Image {
    id
    createdAt
    deletedAt
    favorite
    format
    generator {
      mode
      model
      prompt
      negativePrompt
      seed
      status
    }
    hasWatermark
    height
    isUpload
    name
    url
    width
  }
`;

export const imageMinFragment = gql`
  fragment imageMinFragment on Image {
    id
    favorite
    generator {
      prompt
      negativePrompt
    }
    hasWatermark
    name
    url
  }
`;

export const imageBlueprintFragment = gql`
  fragment imageBlueprintFragment on Image {
    id
    blueprints {
      id
      hasPurchased
    }
    favorite
    generator {
      prompt
      negativePrompt
    }
    hasWatermark
    name
    url
  }
`;

export const GET_IMAGES_AI_CONNECTION = gql`
  query imageConnection($after: String, $before: String, $deleted: Boolean, $favorite: Boolean,
    $first: Int, $generator: ImageGenerator, $last: Int
  ) {
    imageConnection(after: $after, before: $before, deleted: $deleted, favorite: $favorite,
      first: $first, generator: $generator, last: $last)
    {
      edges {
        cursor
        node {
          ...imageGenFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        startCursor
      }
      totalCount
    }
  }
  ${imageGenFragment}
`;

export interface useImagesConnectionArgs {
  after?: string
  before?: string
  deleted?: boolean
  favorite?: boolean
  fetchPolicy?: FetchPolicy
  first?: number
  generator?: "AI" | "ALL" | "UPLOAD"
  last?: number
  queryDoc?: DocumentNode
}

export function useImagesConnection(args: useImagesConnectionArgs) {
  const {
    after,
    before,
    deleted,
    favorite,
    fetchPolicy = "cache-first",
    first,
    generator,
    last,
    queryDoc = GET_IMAGES_AI_CONNECTION,
  } = args;
  const { data, error, loading, refetch: refetchQuery } = useQuery<{ imageConnection: ImageConnection }>(
    queryDoc,
    {
      variables: { after, before, deleted, favorite, first, generator, last },
      fetchPolicy,
    },
  );
  const imageConnection = useMemo(() =>
    data?.imageConnection ?? { edges: [], pageInfo: {} as any, totalCount: 0 }, [data]);
  const { edges, totalCount, pageInfo } = imageConnection;
  const { endCursor, hasNextPage, hasPreviousPage, startCursor } = pageInfo;
  // allImages is used by infinite scroller
  const [allImages, setAllImages] = useState<Image[]>([]);
  const [imageRefetchCount, setImageRefetchCount] = useState(1);

  const refetch = useCallback(async () => {
    setAllImages([]);
    const result = await refetchQuery();
    // Hacky way to force update of allImages if edges doesn't change.
    setImageRefetchCount(c => c + 1);
    return result;
  }, [refetchQuery]);

  useEffect(() => {
    if (!loading && imageRefetchCount) {
      setAllImages(existingImages => {
        const newImages: Image[] = edges.map(e => e.node);
        // Remove dupes
        const deDupedImages = newImages.reduce(
          (acc, item) => {
            if (!acc.find(aitm => aitm.id === item.id)) {
              acc.push(item);
            }
            return acc;
          },
          [...existingImages],
        );
        // Sort by newest first
        deDupedImages.sort((a: Image, b: Image) => {
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          return 0;
        });
        if (favorite) {
          return deDupedImages.filter(ddi => ddi.favorite);
        }
        return deDupedImages;
      });
    }
  }, [edges, favorite, imageRefetchCount, loading]);

  return {
    allImages, endCursor, hasNextPage, hasPreviousPage, error, loading, edges, refetch, startCursor, totalCount,
  };
}

// --------------------------------------------------------
export const GET_IMAGES = gql`
  query images($ids: [ID!]!) {
    images(ids: $ids) {
      ...imageGenFragment
    }
  }
  ${imageGenFragment}
`;

export interface useImagesArgs {
  fetchPolicy?: FetchPolicy
  ids: string[]
}

// Hook version of fetching images
export function useImages(args: useImagesArgs) {
  const { fetchPolicy, ids } = args;
  const { data, error, loading, refetch } = useQuery<{ images: Image[] }>(GET_IMAGES, {
    variables: { ids },
    fetchPolicy,
  });
  const images = useMemo(() => data?.images, [data]);
  return { images, error, loading, refetch };
}

// Imperative version of fetching images
export interface getImagesArgs {
  client: ApolloClient<NormalizedCacheObject>
  fetchPolicy?: FetchPolicy
  ids: string[]
}

export async function getImages(args: getImagesArgs) {
  const { client, fetchPolicy, ids } = args;
  try {
    const { data, errors, loading } = await client.query({
      query: GET_IMAGES,
      variables: { ids },
      fetchPolicy,
    });
    const images: Image[] = data?.images ?? [];
    return { images, errors, loading };
  }
  catch (e: any) {
    return {
      images: [],
      errors: getSubErrors(e),
      loading: false,
    };
  }
}

// --------------------------------------------------------
export const GET_IMAGES_WITH_BLUEPRINTS = gql`
  query images($ids: [ID!]!) {
    images(ids: $ids) {
      ...imageBlueprintFragment
    }
  }
  ${imageBlueprintFragment}
`;

// Imperative version of fetching images with associated blueprints
export interface getImagesWithBlueprintsArgs {
  client: ApolloClient<NormalizedCacheObject>
  fetchPolicy?: FetchPolicy
  ids: string[]
}

export async function getImagesWithBlueprints(args: getImagesWithBlueprintsArgs) {
  const { client, fetchPolicy, ids } = args;
  try {
    const { data, errors, loading } = await client.query({
      query: GET_IMAGES_WITH_BLUEPRINTS,
      variables: { ids },
      fetchPolicy,
    });
    const images: Image[] = data?.images ?? [];
    return { images, errors, loading };
  }
  catch (e: any) {
    return {
      images: [],
      errors: getSubErrors(e),
      loading: false,
    };
  }
}

// --------------------------------------------------------
export const CREATE_IMAGE = gql`
  mutation createImage($input: CreateImageInput!) {
    createImage(input: $input) {
      id
      generator {
        status
      }
      hasWatermark
      url
    }
  }
`;

export function useCreateImage() {
  // returns [createImage, { data, loading, error }]
  return useMutation<{ createImage: Image }>(CREATE_IMAGE, {
    refetchQueries: [
      GET_IMAGES_AI_CONNECTION,
      GET_IMAGES,
    ],
  });
}

// --------------------------------------------------------
export const DELETE_IMAGES = gql`
  mutation deleteImages($input: DeleteImageInput!) {
    deleteImages(input: $input)
  }
`;

export function useDeleteImages(refetchQueries?: DocumentNode[]) {
  // returns [deleteImages, { data, loading, error }]
  return useMutation<{ deleteImages: Boolean }>(DELETE_IMAGES, {
    refetchQueries,
  });
}

// --------------------------------------------------------
export const UNDELETE_IMAGES = gql`
  mutation undeleteImages($input: UndeleteImageInput!) {
    undeleteImages(input: $input)
  }
`;

export function useUndeleteImages(refetchQueries?: DocumentNode[]) {
  return useMutation<{ undeleteImages: Boolean }>(UNDELETE_IMAGES, {
    refetchQueries,
  });
}

// --------------------------------------------------------
export const UPDATE_IMAGES = gql`
  mutation updateImages($input: UpdateImageInput!) {
    updateImages(input: $input) {
      ...imageGenFragment
    }
  }
  ${imageGenFragment}
`;

export function useUpdateImages() {
  return useMutation<{ updateImages: Image[] }>(UPDATE_IMAGES, {
    refetchQueries: [
      GET_IMAGES_AI_CONNECTION,
      "imageConnection", // Query name
    ],
  });
}

// --------------------------------------------------------
export async function updateImagesCache(
  client: ApolloClient<NormalizedCacheObject>,
  ids: string[],
) {
  await client.refetchQueries({
    updateCache(cache) {
      ids.forEach(id => {
        cache.evict({ id: `Image:${id}` });
      });
    },
  });
}

// --------------------------------------------------------
export async function clearImagesCache(client: ApolloClient<NormalizedCacheObject>) {
  await client.refetchQueries({
    updateCache(cache) {
      cache.evict({ id: "ROOT_QUERY", fieldName: "images", broadcast: false });
      cache.evict({ id: "ROOT_QUERY", fieldName: "imageConnection", broadcast: false });
      cache.evict({ id: "Image", broadcast: false });
      cache.gc();
    },
  });
}

// --------------------------------------------------------
export const GENERATE_UPLOAD_URL = gql`
  mutation generateUploadUrl($input: GenerateUploadUrlInput!) {
    generateUploadUrl(input: $input) {
      url
      uploadUrl
    }
  }
`;

export function useGenerateUploadUrl() {
  // returns [generateUploadUrl, { data, loading, error }]
  return useMutation<{ generateUploadUrl: UploadImageUrlPayload }>(GENERATE_UPLOAD_URL);
}

export function generateUploadUrl(input: UploadUrlInput) {
  return apolloClient.mutate({
    mutation: GENERATE_UPLOAD_URL,
    variables: { input },
  });
}

// --------------------------------------------------------
export const UPLOAD_IMAGE_URL = gql`
  mutation uploadImageUrl($input: UploadImageUrlInput!) {
    uploadImageUrl(input: $input) {
      uploadUrl
    }
  }
`;

export function useUploadImageUrl() {
  return useMutation<{ uploadImageUrl: UploadImageUrlPayload }>(UPLOAD_IMAGE_URL);
}
