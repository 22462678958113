// Reusable dialog component
import { useMemo } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { ColorPaletteProp, VariantProp } from "@mui/joy/styles";

export interface FurnDialogArgs {
  body?: string | JSX.Element
  cancelButtonColor?: ColorPaletteProp
  cancelButtonVariant?: VariantProp
  cancelText?: string
  isBusy: boolean
  isOpen: boolean
  okButtonColor?: ColorPaletteProp
  okButtonVariant?: VariantProp
  okText?: string
  onCancel?: Function
  onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown" | "closeClick") => void
  onOk?: Function
  title?: string
}

export function FurnDialog(props: FurnDialogArgs) {
  const {
    body,
    cancelButtonColor,
    cancelButtonVariant,
    cancelText = "Cancel",
    isBusy,
    isOpen,
    okButtonColor,
    okButtonVariant,
    okText = "Ok",
    onCancel,
    onClose,
    onOk,
    title,
  } = props;

  const { contentBody, sheetStyle } = useMemo(() => {
    if (typeof body === "string") {
      return {
        contentBody: (
          <Typography id="modal-desc" textColor="text.tertiary">
            {body}
          </Typography>
        ),
        sheetStyle: sheetContainerStyle,
      };
    }
    return {
      contentBody: body,
      sheetStyle: { ...sheetContainerStyle, ...bodyStyle },
    };
  }, [body]);

  return (
    <Modal
      aria-labelledby={title}
      open={isOpen}
      onClose={onClose}
      sx={modalStyle}
    >
      <Sheet
        variant="outlined"
        sx={sheetStyle}
      >
        <ModalClose color="primary" sx={modalCloseStyle} />
        <Typography
          component="h2"
          id="modal-title"
          level="h4"
          textColor="inherit"
          style={modalTitleStyle}
        >
          {title}
        </Typography>

        {contentBody}

        <Box sx={buttonsContainerStyle}>
          { onOk && (
            <Button
              onClick={onOk as any}
              color={okButtonColor}
              disabled={isBusy}
              variant={okButtonVariant}
            >
              {okText}
            </Button>
          )}
          { onCancel && (
            <Button
              onClick={onCancel as any}
              color={cancelButtonColor}
              disabled={isBusy}
              variant={cancelButtonVariant}
            >
              {cancelText}
            </Button>
          )}
        </Box>
      </Sheet>
    </Modal>
  );
}

const modalStyle = {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
};

const sheetContainerStyle = {
  borderRadius: "md",
  boxShadow: "lg",
  p: 3,
};

const bodyStyle = {
  height: "90%",
  width: "90%",
};

const buttonsContainerStyle = {
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  marginTop: "2rem",
};

const modalCloseStyle = {
  m: 1,
};

const modalTitleStyle = {
  fontWeight: "lg",
  mb: 1,
};
