// Image Gallery with infinite scrolling

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import Box from "@mui/joy/Box";
import CircularProgress from "@mui/joy/CircularProgress";
import IconButton from "@mui/joy/IconButton";
import ImageList from "@mui/material/ImageList";
import { Typography } from "@mui/joy";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import ReplayIcon from "@mui/icons-material/Replay";
import Tooltip from "@mui/joy/Tooltip";
import {
  GET_IMAGES_AI_CONNECTION,
  clearImagesCache,
  useImagesConnection,
  useUploadImageUrl,
} from "../graphql/image";
import { apolloClient } from "../graphql/apolloClient";
import { ImageAi } from "./imageAi";
import { ImageUpload, onUploadInputArgs } from "./imageUpload";
import { FurnDialog } from "./furnDialog";
import { ImageFormatEnum } from "../models/image";

export interface ImageGalleryArgs {
  favorite?: boolean
  generator?: "AI" | "ALL" | "UPLOAD"
  mode?: "regular" | "trash"
  offsetIncrement?: number // # pixels above the bottom list element where the buffer increments
  onSelect?: Function
  pageSize?: number // number of items to render at start
  subtitle?: string
  title?: string
}

export function ImageGallery(props: ImageGalleryArgs) {
  const {
    favorite,
    generator,
    mode = "regular",
    offsetIncrement = 80,
    onSelect,
    pageSize = 12,
    subtitle,
    title,
  } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [before, setBefore] = useState<string | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isReloading, setIsReloading] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const isLarge = useMediaQuery(theme.breakpoints.down("xl"));
  const [openModal, setOpenModal] = useState(false);
  const [uploadInputs, setUploadInputs] = useState<onUploadInputArgs>();
  const [uploadImageUrl, { error: uploadError }] = useUploadImageUrl();

  const {
    allImages, loading, edges, refetch, startCursor, totalCount,
  } = useImagesConnection({
    before,
    deleted: mode === "trash",
    favorite,
    last: pageSize,
    generator,
    queryDoc: GET_IMAGES_AI_CONNECTION,
  });

  const isBusy = useMemo(() => isReloading || loading, [loading, isReloading]);

  const refetchClean = useCallback(async () => {
    setBefore(undefined);
    setIsReloading(true);
    await clearImagesCache(apolloClient);
    const { loading: refetching } = await refetch();
    setIsReloading(refetching);
  }, [refetch]);

  const uploadModalOpen = useCallback(() => setOpenModal(true), []);
  const uploadModalClose = useCallback(() => {
    setUploadInputs(undefined);
    setOpenModal(false);
  }, []);

  const onUploadInput = useCallback((args: onUploadInputArgs) => {
    setUploadInputs(args);
  }, []);

  const handleUploadImage = useCallback(async () => {
    // Call API to get secure upload URL and upload file.
    if (!uploadInputs?.image) {
      return;
    }
    const { description, image } = uploadInputs;
    setIsReloading(true);
    const { name: filename, type } = image;
    const response = await uploadImageUrl({
      variables: {
        input: {
          description,
          filename,
          format: ImageFormatEnum[type as keyof typeof ImageFormatEnum],
        },
      },
    });

    const { uploadUrl /* url */ } = response.data?.uploadImageUrl ?? {};
    try {
      if (!uploadUrl || uploadError) {
        setIsReloading(false);
        throw new Error("Invalid uploadUrl");
      }

      await fetch(uploadUrl, {
        method: "PUT",
        body: image,
      });
    }
    catch (e) {
      // ToDo: show error
      setIsReloading(false);
      uploadModalClose();
      return;
    }

    uploadModalClose();
    refetchClean();
  }, [refetchClean, uploadImageUrl, uploadInputs, uploadModalClose, uploadError]);

  const imageItems = useMemo(() => {
    if ((allImages.length === 0) && (mode === "regular") && !loading) {
      return (
        <Box style={loadingContainerStyle}>
          <Typography level="title-md">
            {favorite ? "Favorite" : "Custom"} furniture will appear here
          </Typography>
        </Box>
      );
    }

    let cols = 4;
    if (isXSmall) {
      cols = 1;
    }
    else if (isSmall) {
      cols = 2;
    }
    else if (isLarge) {
      cols = 3;
    }
    return (
      <ImageList cols={cols} gap={8}>
        {allImages.map(img => (
          <ImageAi image={img} mode={mode} refetch={refetchClean} key={img.id} onSelect={onSelect} />
        ))}
      </ImageList>
    );
  }, [allImages, favorite, isLarge, isSmall, isXSmall, loading, mode, onSelect, refetchClean]);

  useEffect(() => {
    // Reset `before` arg if images were cleared (e.g. after mutation)
    if (!allImages.length) {
      setBefore(undefined);
    }
  }, [allImages]);

  const scrollDebounce = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    const handleScroll = () => {
      if (scrollDebounce.current) {
        // Debounce prevents double firing page requests
        clearTimeout(scrollDebounce.current);
        scrollDebounce.current = undefined;
      }
      scrollDebounce.current = setTimeout(() => {
        if (
          (containerRef.current?.offsetTop || Number.POSITIVE_INFINITY)
          <= window.innerHeight
          + document.documentElement.scrollTop
          + offsetIncrement
          && allImages.length < totalCount
        ) {
          setBefore(startCursor);
        }
      }, 100);
    };
    const _containerRef = containerRef.current;
    _containerRef?.addEventListener("scroll", handleScroll);
    // Cleanup
    return () => _containerRef?.removeEventListener("scroll", handleScroll);
  }, [allImages.length, edges, offsetIncrement, startCursor, totalCount]);

  return (
    <Box ref={containerRef} sx={containerStyle}>
      <Box sx={titleContainerStyle}>
        <Box>
          <Typography level="h2" style={titleStyle} color="primary">{title}</Typography>
          <Typography level="body-sm">{subtitle}</Typography>
        </Box>
        {!onSelect && <Box>
          <IconButton onClick={uploadModalOpen} variant="plain">
            <Tooltip title="Upload image" variant="soft">
              <AddPhotoAlternateOutlinedIcon />
            </Tooltip>
          </IconButton>
          <IconButton onClick={refetchClean} variant="plain">
            <Tooltip title="Reload" variant="soft">
              <ReplayIcon />
            </Tooltip>
          </IconButton>
        </Box>}
      </Box>

      {imageItems}

      <FurnDialog
        body={<ImageUpload onInput={onUploadInput} />}
        isBusy={isBusy}
        isOpen={openModal}
        cancelButtonVariant="outlined"
        onCancel={uploadModalClose}
        onClose={uploadModalClose}
        onOk={handleUploadImage}
        okText="Upload"
      />
      {isBusy && (
        <Box style={loadingContainerStyle}>
          <CircularProgress size="lg" />
        </Box>
      )}
    </Box>
  );
}

const containerStyle = {
  maxHeight: "92vh",
  overflowY: "auto",
  position: "absolute",
  maxWidth: "97vw",
};

const loadingContainerStyle = {
  display: "flex",
  justifyContent: "center",
  margin: "4rem",
};

const titleContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
};

const titleStyle = {
  marginTop: "2rem",
  marginBottom: "1rem",
};
