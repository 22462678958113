import { gql } from "@apollo/client";
import { configFragment } from "./configFragments";

export const UserFragment = gql`
  fragment userFragment on User {
    id
    configuration {
      ...configFragment
    }
    createdAt
    email
    firstName
    lastName
    organization {
      id
      name
      users {
        id
        firstName
        lastName
        personas
        email
        role
      }
    }
    personas
    remainingBlueprintCredits
    role
  }
  ${configFragment}
`;
