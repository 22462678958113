import { useCallback, useEffect, useRef, useState } from "react";
import { getImageDimensionsFromFile, resizeImage } from "../util/common";

interface Props {
  backgroundUrl?: string
  height?: number
  imageFile?: File | null
  width?: number
}

export const Canvas: React.FC<Props> = props => {
  const {
    backgroundUrl,
    height: propsHeight,
    imageFile,
    width,
  } = props;
  const [canvasHeight, setCanvasHeight] = useState(1);
  const canvasRef = useRef<HTMLCanvasElement>();
  const updateBitmap = useCallback(async (file?: File | null) => {
    if (!canvasRef.current || !file) return;
    const { height: imgHeight, width: imgWidth } = await getImageDimensionsFromFile(file);
    const { width: canvasWidth, height: curentCanvasHeight } = canvasRef.current;
    // Scale image to canvasWidth and retain aspect ratio for height.
    const arCanvasHeight = Math.min(imgHeight, Math.round((canvasWidth / imgWidth) * imgHeight));
    const _canvasHeight = arCanvasHeight ?? curentCanvasHeight ?? canvasHeight ?? canvasWidth;
    setCanvasHeight(_canvasHeight);

    // Fit image to canvas dimensions
    const resizedBlob = await resizeImage({
      file,
      maxHeight: _canvasHeight,
      maxWidth: canvasWidth,
    });
    if (!resizedBlob) throw new Error("Resize failed");
    const ctx = canvasRef.current.getContext("2d");
    const bitmap = await createImageBitmap(resizedBlob as Blob);
    let xOffset = 0;
    if (canvasWidth > imgWidth) {
      xOffset = Math.round((canvasWidth - imgWidth) / 2);
    }
    ctx?.clearRect(0, 0, canvasWidth, _canvasHeight);
    ctx?.drawImage(bitmap, xOffset, 0);
  }, [canvasHeight]);

  useEffect(() => {
    updateBitmap(imageFile);
  }, [imageFile, updateBitmap]);

  const fill = "%23000000";
  const height = ((propsHeight || canvasHeight || width) ?? 0) + 2;
  const widthHalf = (width ?? 0) / 2;
  const style = {
    ...(backgroundUrl ? { background: `url(${backgroundUrl}) no-repeat top center / contain` } : undefined),
    cursor: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" 
    fill=${fill} opacity="0.3" viewBox="0 0 ${width} ${height}" width="${width} 
    height="${height}"><circle cx="${widthHalf}" cy="${widthHalf}" 
    r="${widthHalf}" fill=${fill} /></svg>') ${widthHalf} ${widthHalf}, auto
    `,
  } as any;

  return (
    <section style={{ height }} >
      <canvas height={height} width={width} style={style} ref={canvasRef as any} />
    </section>
  );
};
