import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import Grid from "@mui/joy/Grid";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import CloseIcon from "@mui/icons-material/Close";
import PhotoIcon from "@mui/icons-material/Photo";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { ImageAi } from "./imageAi";
import { AiControls } from "./imageAiControls";
import { AiModel } from "../models/config";
import { apolloClient } from "../graphql/apolloClient";
import { clearImagesCache, useCreateImage } from "../graphql/image";
import { useUser } from "../graphql/user";
import { ImageGeneratorValuesType, useAiGeneratorTabs } from "../hooks/useAiGeneratorTabs";
import { GeneratorMode, Image } from "../models/image";
import { errorMessages } from "../util/errorMessages";
import { routes } from "../pages/routes";
import { ImageGallery } from "./imageGallery";
import { FurnDialog } from "./furnDialog";

export function ImageGenerator() {
  const { user } = useUser();
  // const config = useConfig();
  const aiModels = useMemo<AiModel[]>(
    () =>
      user?.configuration.aiModels?.map(aim => {
        const { __typename, ...restModel } = aim as any;
        return restModel;
      }),
    [user?.configuration],
  );
  const [genError, setGenError] = useState<string[]>([]);
  const [generatedImage, setGeneratedImage] = useState<Image | undefined>();
  const [selectedImage, setSelectedImage] = useState<Image | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [needsUpgrade, setNeedsUpgrade] = useState(false);
  const navigate = useNavigate();
  const [createImage, { data, loading: isCreating }] = useCreateImage();
  const { generatorValues: values, setGeneratorValues } = useAiGeneratorTabs();

  const isBusy = useMemo(() => isCreating || isUpdating, [isCreating, isUpdating]);

  useEffect(() => {
    if (values.model) {
      setGeneratorValues((vals: ImageGeneratorValuesType) => {
        if (values.isRegenerate) {
          return vals; // Regeneration supplies all the params
        }
        const { label, name, ...selectedModel } = aiModels.find(aim => aim.name === values.model) ?? {};
        return { ...selectedModel, ...vals };
      });
    }
  }, [aiModels, values.model, setGeneratorValues, values.isRegenerate]);

  const handleGenerateSubmit = useCallback(async (event: SyntheticEvent) => {
    event.preventDefault();
    setGenError([]);
    setIsUpdating(true);
    setGeneratedImage(undefined);
    const { isRegenerate, mode, ...createValues } = values;
    const imageId = selectedImage?.id;
    const generatorMode = imageId ? GeneratorMode.IMAGE : GeneratorMode.TEXT;
    const input = {
      ...createValues,
      generatorMode,
      imageId,
    };

    try {
      // throw new Error("err");
      await createImage({
        variables: { input },
      });
    }
    catch (error: any) {
      if (error?.message.includes("Exceeded AI generations")) {
        setGenError(errorMessages.aiExceeded);
        setNeedsUpgrade(true);
      }
      else if (error?.message.includes("Not authorized")) {
        setGenError(errorMessages.unauthorized);
      }
      setIsUpdating(false);
      return;
    }
    await clearImagesCache(apolloClient);
  }, [createImage, selectedImage?.id, values]);

  useEffect(() => {
    const newImage = data?.createImage;
    if (newImage?.generator.status === "ERROR") {
      setGenError(["Image generation failed"]);
      return;
    }
    setGeneratedImage(newImage);
  }, [data?.createImage]);

  const handleUpgrade = useCallback(async (event: SyntheticEvent) => {
    navigate(routes.billing.route);
  }, [navigate]);

  const genResult = useMemo(() => {
    if (genError.length) {
      return (
        <>
          {genError.map((errMsg, i) => (
            <Typography level="title-md" fontWeight="lg" mb={1} key={i}>
              {errMsg}
            </Typography>
          ))}
          {needsUpgrade && (
            <Button onClick={handleUpgrade} disabled={isBusy}>
              Upgrade Plan
            </Button>
          )}
        </>
      );
    }
    if (generatedImage) {
      return (
        <ImageAi image={generatedImage} setIsUpdating={setIsUpdating} />
      );
    }
    if (isBusy) {
      return (
        <>
          <Box>
            <Typography>Furniture image generation may take up to 30 seconds...</Typography>
          </Box>
          <CircularProgress size="lg" sx={spinnerStyle} />
        </>
      );
    }

    return (
      <>
        <PhotoIcon style={photoIconStyle} />
        <Typography>
          Custom furniture will appear here
        </Typography>
      </>
    );
  }, [genError, generatedImage, handleUpgrade, isBusy, needsUpgrade]);

  const selectModalOpen = useCallback(() => setOpenModal(true), []);
  const handleImageCancel = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleImageSelect = useCallback((image: Image) => {
    setSelectedImage(image);
    setOpenModal(false);
  }, []);

  const clearSelectedImage = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setSelectedImage(undefined);
  }, []);

  const controls = useMemo(() => [
    /* {
        title: "Model",
        filterModes: [GeneratorMode.TEXT],
        onChange: (event: Event, model: ImageGeneratorValuesType["model"]) => {
          setGeneratorValues({ ...values, isRegenerate: false, model });
        },
        options: aiModels?.map(aim => ({ value: aim.name, label: aim.label })),
        placeholder: "Choose a model",
        required: true,
        tip: "Choose an appropriate model for the best results",
        type: "select" as const,
        value: values.model,
      }, */
    {
      title: "Name",
      onBlur: (e: React.FocusEventHandler<HTMLTextAreaElement>) => {
        setGeneratorValues({ ...values, name: values.name?.trim() });
      },
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setGeneratorValues({ ...values, name: e?.target?.value });
      },
      tip: "Optional name for reference",
      type: "text" as const,
      value: values.name ?? "",
    },
    {
      title: "Prompt",
      onBlur: (e: React.FocusEventHandler<HTMLTextAreaElement>) => {
        setGeneratorValues({ ...values, prompt: values.prompt?.trim() });
      },
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setGeneratorValues({ ...values, prompt: e?.target?.value });
      },
      required: true,
      tip: "Describe the scene, the more detailed the better, separated by commas",
      type: "text" as const,
      value: values.prompt,
    },
  ], [setGeneratorValues, values]);

  return (
    <Box>
      <Typography level="h2" style={titleStyle} color="primary">
        Generate custom furniture with AI
      </Typography>

      <form onSubmit={handleGenerateSubmit}>
        <Grid container spacing={2} style={gridContainerStyle}>
          <Grid xs={12} md={6} style={gridCellStyle}>
            <AiControls
              controls={controls}
              isBusy={isBusy}
              selectedMode={values.mode as string}
            />

            <Grid xs={12} md={6} style={imageSelectCellStyle}>
              <Box>
                <Button onClick={selectModalOpen}
                  variant={"outlined"}
                  disabled={isBusy}
                >
                  Optional reference image
                  {selectedImage ?
                    <Box style={imagePrevBoxStyle}>
                      <img
                        alt="reference image"
                        src={selectedImage.url}
                        style={isBusy ? imgPreviewDisabledStyle : imgPreviewStyle}
                      />
                      <IconButton
                        disabled={isBusy}
                        onClick={clearSelectedImage}
                        style={clearSelectedImageStyle}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    :
                    <PhotoIcon style={imagePrevBoxStyle} />
                  }
                </Button>
              </Box>
            </Grid>

            <Grid xs={12} md={6} style={gridCellStyle}>
              <Button type="submit" style={submitButtonStyle} disabled={isBusy}>
                <AutoAwesomeOutlinedIcon sx={{ mr: 1 }} />Generate Furniture
              </Button>
            </Grid>
          </Grid>
          <Grid xs={12} md={6} style={gridCellStyle}>
            <Sheet style={genResultContainerStyle}>
              <Box sx={genImageContainerStyle}>
                {genResult}
              </Box>
            </Sheet>
          </Grid>
        </Grid>
      </form>
      <FurnDialog
        body={<ImageGallery title="Select an image" generator="ALL" onSelect={handleImageSelect} />}
        isBusy={isBusy}
        isOpen={openModal}
        cancelButtonVariant="outlined"
        onClose={handleImageCancel}
      />
    </Box>
  );
}

const gridContainerStyle = {
  display: "flex",
  flexDirection: "row",
  maxHeight: "70vh",
} as React.CSSProperties;

const genImageContainerStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const genResultContainerStyle = {
  height: "100%",
  display: "flex",
  alignItems: "start",
  justifyContent: "center",
};

const gridCellStyle = {
  paddingTop: 0,
  paddingBottom: 0,
};

const imageSelectCellStyle = {
  ...gridCellStyle,
  alignItems: "left",
  display: "flex",
  flexDirection: "column" as const,
  marginTop: "2rem",
};
const imgPreviewStyle = {
  justifyContent: "space-evenly",
  width: "90%",
};

const imgPreviewDisabledStyle = {
  ...imgPreviewStyle,
  opacity: 0.5,
};

const imagePrevBoxStyle = {
  display: "flex",
  marginLeft: "0.5rem",
};

const clearSelectedImageStyle = {
  alignSelf: "start",
};

const photoIconStyle = {
  alignSelf: "center",
  fontSize: "12rem",
};

const spinnerStyle = {
  margin: "1rem",
};

const submitButtonStyle = {
  marginTop: "2rem",
  width: "100%",
};

const titleStyle = {
  marginTop: "2rem",
  marginBottom: "1rem",
};
